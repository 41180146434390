* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root{
  min-height: 100%;
}

body{
  font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
  font-size: 14px;
  background-color: #eeeeee !important;
}

.wrapper{
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-between;
}

.content{
    width: calc(100% - 50px);
    padding: 15px;
    overflow-x: hidden;
}

/* Variaveis */

:root{
    /*  */
    --smooth:200ms ease-in-out;
}

*::-webkit-scrollbar-track
{
    background-color: #ddd;
}
*::-webkit-scrollbar
{
    background-color: #ddd;
    border-radius: 10px;
}
*::-webkit-scrollbar-thumb
{
    border-radius: 10px;
}

.form-control .form-control-color{
    padding: .375rem 14rem !important;
}
.form-group .react-datepicker-wrapper{
    width: 100%;
}

